.home_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 2rem;
    padding: 10vh;

}

.extra_container {
    padding: 5rem;
    padding-right: 3rem;
}

@media (max-width: 1200px) {
    
    .home_container{ 
        flex-direction: column;
        justify-content: center;
        align-items: center;
        column-gap: 0rem;
        padding: 3vh;
    }

    .extra_container {
        padding: 0rem;
        align-items: center;
        justify-content: center;
    }
  }
