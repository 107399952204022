.logo {
    color: #5d5d5d6d;
    font-family: 'Roboto', sans-serif;
    font-family: 'Inter', sans-serif;

}

.navbar_container {
    padding-top: 3vh;
}
.profile_img {
    border-radius: 100%;
    max-width: 60px;
    position: flex;
    margin-left: -1.5rem;
    cursor: pointer;
    border-width: 1px;
    border-color: grey;
}

@media (max-width:991.98px) {
    .profile_img {
        display: none;
    } 

    .logo {
        display: none;
    }

}

.link-bg-celeste {
    background-color: #00bfff15;
    border-radius: 0.5rem;
    padding-left: 6rem;
    padding-right: 6rem;
  }
  
.modal-content {
    background-color: rgba(128, 128, 128, 0.196);
    border-width: 1px;
    width: 100%;
    border-color: rgb(54, 54, 54);
    border-radius: 50%;
        box-shadow:
        inset 0 -3em 3em rgba(0, 0, 0, 0.5),
        0 0 0 0.1px rgb(69, 69, 69),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.5);
  } 


