body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  /* overflow: hidden; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/background.jpg");
  background-attachment: fixed;
  background-size:cover;
  /* display: static; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.selector-for-some-widget {
  box-sizing: content-box;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Roboto&display=swap');