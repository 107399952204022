

.skills_icon {
    max-width: 150px;
    min-width: 150px;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.extra_container {
    justify-items: center;
    align-items: center;
    display: flex;
    min-width: 200px;
    min-height: 300px;
}



  