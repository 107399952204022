.description_container {
    width: 400px;
    height: 500px;
    background-color: rgb(8, 8, 92);

}

.card {
    box-shadow:
        inset 0 -2em 5em rgba(0, 0, 0, 0.05),
        0 0 0 0.1px rgb(69, 69, 69),
        0.3em 0.3em 1em rgba(0, 0, 0, 0.1);
    background-color: transparent;
  }