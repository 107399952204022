.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 991px) {
  .principal_container{ 
    min-width: 75%;
  }
}

@media (max-width: 600px) {
  .principal_container{ 
    width: 90%;
    min-width: 450px;
  }
}

.App-header {  

  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #61dafb;
}

.principal_container{
  /* position: relative; */
  top: 50%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 5%;
  /* -height/2 */
  width: 75%;
  border-radius: 0.8rem;
  height: auto;
  background-color: whitesmoke;
  min-height: 80vh;
  box-shadow:
  inset 0 -3em 3em rgba(0, 0, 0, 0.1),
  0 0 0 1px rgb(69, 69, 69),
  1em 2em 1em rgba(0, 0, 0, 100);
   
 }

 .text-brown{
  color: #cb8675;
 }
 .text-brownest{
  color: #a96d60;
 }
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
