
.details_container {
    border-radius: 5rem;
}


.slider_images{
    width: 500px;
    padding: 2rem;
}

.description{
    padding: 2rem;
}

.modal_box {
    margin: auto;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;
    padding: 2rem;
    align-items: center;
    justify-content: center;
    column-gap: 2rem;
    padding: 2rem;
}

.proyect_body{
    border: 1px;
}

.slider {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 1rem;
    padding: 1vh;
}

.navBoton {
    font-size: 40px;
    margin-top: 15%;
    margin-left: 3%;
    margin-right: 3%;
    width: 5%;
    background-color: rgba(78, 78, 78, 0);
    border-style:none;
    cursor: pointer;
}

.navBoton-disabled{
    font-size: 40px;
    margin-top: 15%;
    margin-left: 3%;
    margin-right: 3%;
    width: 5%;
    background-color: rgba(78, 78, 78, 0);
    border-style:none;
    opacity: 0;
    cursor: none;
}
.proyect_photo {
    border-radius: 0.5rem;
    box-shadow:
    inset 0 -0em 0em rgba(0, 0, 0, 0.5),
    0 0 0 1px rgb(69, 69, 69),
    0em 0.3em 0.5em rgba(0, 0, 0, 0.5);
}
.icon {
    max-width: 22px;
    margin-bottom: 0.3%;
}
