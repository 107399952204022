.contact-container {
    padding: 5rem;
}

.loading-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    z-index: 999999999;
    background: var(--text-color);
    transform: translateX(100%);
    animation: shift-rightwards 1s ease-in-out infinite;
    animation-delay: .3s;
}

.contact_form ::placeholder {
    color: white;
}


@keyframes shift-rightwards {
    0% {
        transform: translateX(-100%);
    }

    40% {
        transform: translateX(0%);
    }

    60% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}